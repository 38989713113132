import React from 'react';
import Section from '../../Section/index.tsx';
import ServicesBlocks from './Services.tsx';
import services from '../../../data/Services.tsx';
import './styles.css';

function Services(): JSX.Element {
  return (
    <Section
      header="My Services"
      subheader="A blend of technical and creative 
      abilities with expertise in front-end development 
      technologies like TypeScript, React, and Next.js, 
      coupled with proficiency in design tools such as Figma, 
      Illustrator and Photoshop. "
    >
      <ServicesBlocks services={services} />
    </Section>
  );
}
export default Services;
