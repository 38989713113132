import React from 'react';
import MainContent from './MainContent/index.tsx';
import SubContent from './SubContent/index.tsx';

function HeaderSection(): JSX.Element {
  return (
    <>
      <MainContent />
      <SubContent />
    </>
  );
}
export default HeaderSection;
