import React from 'react';
import './styles.css';

function GreetingHeader(): JSX.Element {
  return (
    <>
      <h3>Hello,</h3>
      <h1>I'm Maria Zogogianni</h1>
      <h3>
        <span>Front-end</span> Designer/Developer
      </h3>
    </>
  );
}
export default GreetingHeader;
