import React from 'react';
import Section from '../../Section/index.tsx';
import WorksBlocks from './Works.tsx';
import works from '../../../data/Works.tsx';
import './styles.css';

function WorkHistory(): JSX.Element {
  return (
    <Section header="Work History" backgroundColor="#F9F9F9">
      <WorksBlocks works={works} />
    </Section>
  );
}
export default WorkHistory;
