import React from 'react';

function Footer(): JSX.Element {
  return (
    <footer>
      <p>© Maria Zogogianni 2023. All Rights Reserved.</p>
    </footer>
  );
}
export default Footer;
