import React from 'react';
import LinkedinIcon from './LinkedinIcon.tsx';
import EmailIcon from './EmailIcon.tsx';
// import GithubIcon from './GithubIcon.tsx';
import './styles.css';

function SocialMediaIcons(): JSX.Element {
  return (
    <div className="icons">
      <LinkedinIcon />
      <EmailIcon />
      {/* <GithubIcon /> */}
    </div>
  );
}
export default SocialMediaIcons;
