import React from 'react';
import './styles.css';

interface ServicesBlocksProps {
  services: {
    header: string;
    subheader: string;
    icon: string;
  }[];
}

function ServicesBlocks(props: ServicesBlocksProps): JSX.Element {
  const { services } = props;
  return (
    <div className="services">
      {services.map((service, index) => (
        <div className="service_block" key={index}>
          <div className="service_icon">
            <img src={service.icon} alt={`${service.header} Icon`} width={65} />
          </div>
          <h4>{service.header}</h4>
          <p>{service.subheader}</p>
        </div>
      ))}
    </div>
  );
}
export default ServicesBlocks;
