import React from 'react';

import Header from '../../components/Homepage/HeaderSection/index.tsx';
import Services from '../../components/Homepage/ServicesSection/index.tsx';
import WorkHistory from '../../components/Homepage/WorkHistory/index.tsx';
// import Portfolio from '../../components/Homepage/PortfolioSection/index.tsx';
import Footer from '../../components/Footer/index.tsx';

function Homepage(): JSX.Element {
  return (
    <>
      <Header />
      <Services />
      <WorkHistory />
      {/* <Portfolio /> */}
      <Footer />
    </>
  );
}
export default Homepage;
