import React from 'react';
import SocialMediaIcons from './SocialMediaIcons/index.tsx';
import GreetingHeader from './GreetingHeader.tsx';
import mariaPhoto from '../../../../assets/maria-photo.png';
import './styles.css';

function MainContent(): JSX.Element {
  return (
    <div className="section">
      <div className="content">
        <div className="greetings">
          <GreetingHeader />
          <SocialMediaIcons />
        </div>
        <img src={mariaPhoto} alt="Maria" width={264} />
      </div>
    </div>
  );
}
export default MainContent;
