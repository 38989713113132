import React from 'react';
import WorkBlock from './WorkBlock/index.tsx';
import './styles.css';

interface WorksBlocksProps {
  works: {
    companyName: string;
    logo: string;
    details: {
      title: string;
      type: string;
      date: string;
      duration: string;
    };
    skills: {
      frontEnd: string;
      design: string;
    };
    link: string;
  }[];
}

function WorksBlocks(props: WorksBlocksProps): JSX.Element {
  const { works } = props;
  return (
    <div className="works">
      {works.map((work, index) => (
        <WorkBlock work={work} key={index} />
      ))}
    </div>
  );
}
export default WorksBlocks;
