import React from 'react';
import './styles.css';

function LinkedinIcon(): JSX.Element {
  return (
    <a
      href="https://www.linkedin.com/in/maria-zogogianni-208a35163/"
      target="_blank"
      rel="noopener noreferrer"
      className="icon"
    >
      <svg width="20" height="20">
        <path
          fill="#fff"
          d="M10.55.16a10,10,0,1,0,10,10A10,10,0,0,0,10.55.16ZM6.77,16.49H3.92V8H6.77ZM5.34,6.89A1.6,1.6,0,0,1,3.7,5.36a1.46,1.46,0,0,1,.48-1.08,1.76,1.76,0,0,1,2.33,0A1.46,1.46,0,0,1,7,5.36,1.6,1.6,0,0,1,5.34,6.89Zm12.07,9.6H14.57V12.38c0-1,0-2.24-1.48-2.24s-1.7,1.07-1.7,2.17v4.18H8.55V8h2.72V9.19h0A3.05,3.05,0,0,1,14,7.83c2.88,0,3.41,1.75,3.41,4Z"
          transform="translate(-0.55 -0.16)"
        ></path>
      </svg>
    </a>
  );
}
export default LinkedinIcon;
