import React from 'react';
import './styles.css';

function SubContent(): JSX.Element {
  return (
    <div className="subsection">
      <p>
        I specialize in translating complex concepts into visually appealing
        user interfaces,
        <span className="desktop_only">
          <br />
        </span>
        wireframes, and assets that seamlessly integrate functionality and
        aesthetics.
      </p>
    </div>
  );
}
export default SubContent;
