import React from 'react';
import './styles.css';
interface SectionProps {
  header: string;
  subheader?: string;
  backgroundColor?: string;
  children: React.ReactNode;
}

function Section(props: SectionProps): JSX.Element {
  const { header, subheader, backgroundColor, children } = props;
  return (
    <div
      className="section_container"
      style={{
        backgroundColor: backgroundColor ? backgroundColor : 'none',
      }}
    >
      <div className="section_content">
        <h3 className="section_header">{header}</h3>
        {subheader && <p className="section_subheader">{subheader}</p>}
        {children}
      </div>
    </div>
  );
}
export default Section;
