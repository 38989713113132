import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
// @ts-ignore
import Homepage from './pages/Homepage/index.tsx';

// import AppRoutes from './routes';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" Component={Homepage} />
        <Route path="/homepage" Component={Homepage} />
        {/* <Route path="/contact" component={Contact} /> */}
        {/* You can add more routes here */}
      </Routes>
    </Router>
  );
}

export default App;
