import BitloopsLogo from '../assets/works/bitloops.svg';
import AvlosLogo from '../assets/works/avlos.svg';

const works = [
  {
    companyName: 'Bitloops',
    logo: BitloopsLogo,
    details: {
      title: 'Junior Front-End software Engineer',
      type: 'Full time',
      date: 'Feb 2022 - Present · ',
      duration: '1 yr 7 months',
    },
    skills: {
      frontEnd: 'React, Next.js, Typescript, Material UI, HTML, CSS',
      design: 'Adobe Illustrator, Figma, Zeplin, Webflow, Miro',
    },
    link: 'https://www.linkedin.com/company/bitloops/',
  },
  {
    companyName: 'Avlos',
    logo: AvlosLogo,
    details: {
      title: 'UI/UX Designer & Front-End Developer',
      type: 'Internship',
      date: 'Jul 2021 - Sep 2021 · ',
      duration: '3 months',
    },
    skills: {
      frontEnd: 'Stencil.js, TypeScript, HTML, CSS, Vue.js, Git',
      design: 'Figma',
    },
    link: 'https://www.linkedin.com/company/avlos/',
  },
];

export default works;
