import React from 'react';
import './styles.css';

interface DetailsProps {
  details: {
    title: string;
    type: string;
    date: string;
    duration: string;
  };
}

function Details(props: DetailsProps): JSX.Element {
  const { details } = props;
  return (
    <div className="work_details">
      <h4 className="work_title">{details.title}</h4>
      <p className="work_type">{details.type}</p>
      <p className="work_date">
        {details.date}
        <span>{details.duration}</span>
      </p>
    </div>
  );
}
export default Details;
