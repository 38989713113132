import FrontEndDevelopmentIcon from '../assets/services/front-end-development.svg';
import UIUXDesignIcon from '../assets/services/ui-ux-design.svg';
import GraphicDesignIcon from '../assets/services/graphic-design.svg';
import WireframingIcon from '../assets/services/wireframing.svg';
import PrototypingIcon from '../assets/services/prototyping.svg';
import CollaborationCommunicationIcon from '../assets/services/collaboration-communication.svg';

const services = [
  {
    header: 'Front-End Development',
    subheader:
      'Build interactive and responsive web interfaces using technologies like TypeScript, React, and Next.js.',
    icon: FrontEndDevelopmentIcon,
  },
  {
    header: 'UI/UX design',
    subheader:
      'Create visually appealing and intuitive user interfaces by Implementing UX best practices.',
    icon: UIUXDesignIcon,
  },
  {
    header: 'Graphic Design',
    subheader:
      'Design logos, icons, and other visual assets to enhance branding and user experience.',
    icon: GraphicDesignIcon,
  },
  {
    header: 'Wireframing',
    subheader:
      'Plan and organize the layout, structure, and placement of different elements.',
    icon: WireframingIcon,
  },
  {
    header: 'Prototyping',
    subheader:
      'Create an interactive experience to test and refine the design concept before development.',
    icon: PrototypingIcon,
  },
  {
    header: 'Collaboration and Communication',
    subheader:
      'Work closely with cross-functional teams, including designers, developers, and stakeholders.',
    icon: CollaborationCommunicationIcon,
  },
];

export default services;
