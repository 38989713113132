import React from 'react';
import './styles.css';

function EmailIcon(): JSX.Element {
  return (
    <a
      href="mailto:mariazogi@gmail.com"
      target="_blank"
      rel="noopener noreferrer"
      className="email-button icon"
    >
      <svg width="20" height="20">
        <path
          fill="#fff"
          d="M10 2.5C5.85938 2.5 2.5 5.85938 2.5 10C2.5 14.1406 5.85938 17.5 10 17.5C10.6914 17.5 11.25 18.0586 11.25 18.75C11.25 19.4414 10.6914 20 10 20C4.47656 20 0 15.5234 0 10C0 4.47656 4.47656 0 10 0C15.5234 0 20 4.47656 20 10V11.25C20 13.3203 18.3203 15 16.25 15C15.1055 15 14.0781 14.4844 13.3906 13.6758C12.5 14.4961 11.3086 15 10 15C7.23828 15 5 12.7617 5 10C5 7.23828 7.23828 5 10 5C11.0898 5 12.0977 5.34766 12.918 5.94141C13.1406 5.74609 13.4297 5.625 13.75 5.625C14.4414 5.625 15 6.18359 15 6.875V10V11.25C15 11.9414 15.5586 12.5 16.25 12.5C16.9414 12.5 17.5 11.9414 17.5 11.25V10C17.5 5.85938 14.1406 2.5 10 2.5ZM12.5 10C12.5 9.33696 12.2366 8.70107 11.7678 8.23223C11.2989 7.76339 10.663 7.5 10 7.5C9.33696 7.5 8.70107 7.76339 8.23223 8.23223C7.76339 8.70107 7.5 9.33696 7.5 10C7.5 10.663 7.76339 11.2989 8.23223 11.7678C8.70107 12.2366 9.33696 12.5 10 12.5C10.663 12.5 11.2989 12.2366 11.7678 11.7678C12.2366 11.2989 12.5 10.663 12.5 10Z"
        />
      </svg>
    </a>
  );
}
export default EmailIcon;
