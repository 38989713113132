import React from 'react';
import Details from './Details/index.tsx';
import './styles.css';

interface WorkBlockProps {
  work: {
    companyName: string;
    logo: string;
    details: {
      title: string;
      type: string;
      date: string;
      duration: string;
    };
    skills: {
      frontEnd: string;
      design: string;
    };
    link: string;
  };
}

function WorkBlock(props: WorkBlockProps): JSX.Element {
  const { work } = props;
  return (
    <a
      href={work.link}
      target="_blank"
      rel="noopener noreferrer"
      className="work_block"
    >
      <div className="work_icon">
        <img src={work.logo} alt={`${work.companyName} Logo`} />
      </div>
      <Details details={work.details} />
      <p className="work_skills">
        <span>✓ Front End: {work.skills.frontEnd}</span>
        <span>✓ Design: {work.skills.design}</span>
      </p>
    </a>
  );
}
export default WorkBlock;
